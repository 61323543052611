<template>
  <div class="menu-content">
    <div class="header">
      <a-button @click="cancel" style="margin-right: 10px">取消</a-button>
      <a-button type="primary" @click="save" :loading="loadingSave">保存</a-button>
    </div>

    <div class="content">
      <div>
        <a-form-model
          ref="ruleForm"
          :model="detail"
          :rules="rules"
          :label-col="{ span: 2 }"
          :wrapper-col="{ span: 22 }"
        >
          <a-form-model-item prop="title" label="页面标题">
            <a-input v-model="detail.title" placeholder="页面标题" />
          </a-form-model-item>
          <a-form-model-item prop="content" label="内容">
            <Edit v-model="detail.content" style="width: 100%" v-if="isInit"></Edit>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import { getNavDetail, updateNavDetail } from '@/api/admin'
import Edit from '@/components/Editor'

export default {
  components: {
    Edit
  },
  data () {
    return {
      detail: {
        title: '',
        content: ''
      },
      rules: {
        title: [
          { required: true, message: '请填写页面名称', trigger: 'change' }
        ]
        // content: [
        //   { required: true, message: '请填写内容', trigger: 'change' }
        // ]
      },
      loadingSave: false,
      isInit: false
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      getNavDetail({
        code: this.$route.query.code,
        p_code: this.$route.query.p_code
      }).then(res => {
        this.isInit = true
        this.detail = res
      }).catch(err => {
        this.$message.error(err.msg)
      })
    },
    save () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loadingSave = true
          updateNavDetail(this.detail).then(res => {
            this.loadingSave = false
            this.$message.success('保存成功')
            this.$router.back()
          }).catch(err => {
            this.loadingSave = false
            this.$message.error(err.msg)
          })
        }
      })
    },
    cancel () {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-content {
  .header {
    position: fixed;
    background: #fff;
    right: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    padding: 0 30px;
    z-index: 1;
  }

  .content {
    padding: 30px;
    padding-top: 90px;
  }
  ::v-deep .ck-content {
    min-height: 400px;
  }
}
</style>
